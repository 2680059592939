import React from 'react';
import logo from './logo.png';
import './App.css';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Videos from './components/videos/videos.js'
import Vlogs from './components/vlogs/vlogs.js'
import Photos from './components/photos/photos.js';
import Contact from './components/contact/contact.js'

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  console.log(children)
  if (value=== index){
    return <div>{children}</div>
  } else {
    return <div></div>
  }
}
function App() {

  const [value, setValue] = React.useState(2);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="disabled tabs example"
        >
          <Tab label="Demo Reel"/>
          <Tab label="Videos"/>
          <Tab label="Vlogs"/>
          <Tab label="Photos"/>
          <Tab label="Contact"/>
      </Tabs>
      </header>

      <TabPanel value={value} index={0} className='panel top-margin'>
        <div className='top-margin'>
          <iframe width="800" height="500" src="https://www.youtube.com/embed/IVtBDtZ6OB0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1} className='panel'>
          {Videos()}
      </TabPanel>
      <TabPanel value={value} index={2} className='panel'>
          {Vlogs()}
      </TabPanel>
      <TabPanel value={value} index={3} className='panel'>
          {Photos()}
      </TabPanel>
      <TabPanel value={value} index={4} className='panel'>
          {Contact()}
      </TabPanel>

    </div>
  );
}

export default App;
