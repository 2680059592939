import React from 'react';
import './style.css';
import Box from '@material-ui/core/Box';


function Vlogs() {

  return (
    <div>
      <header>
        <Box className='vlogs-container'>
            <div className='child'><iframe width="700" height="400" src="https://www.youtube.com/embed/MUTu_M3UAWU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
            <div className='child'><iframe width="700" height="400" src="https://www.youtube.com/embed/kj5OENedRQo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
            <div className='child'><iframe width="700" height="400" src="https://www.youtube.com/embed/kj5OENedRQo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
        </Box>
      </header>
    </div>
  );
}

export default Vlogs;
