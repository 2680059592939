import React from 'react';
import './style.css';
import Box from '@material-ui/core/Box';
import testImage from './test-image.jpeg'


function Photos() {

  return (
    <div>
      <header>
        <Box className='photo-container'> 
            <img className ='photo' src={testImage}/>
            <img className ='photo' src={testImage}/>
            <img className ='photo' src={testImage}/>
            <img className ='photo' src={testImage}/>
            <img className ='photo' src={testImage}/>
            <img className ='photo' src={testImage}/>
            <img className ='photo' src={testImage}/>
            <img className ='photo' src={testImage}/>
        </Box>
      </header>
    </div>
  );
}

export default Photos;
